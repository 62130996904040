:root {
    --header-height: 3rem;
    --footer-height: 1rem;
}

@font-face {
  font-family: "Vera Mono";
  src: url('./fonts/VeraMono-webfont.woff') format('woff');
  font-style: normal;
  font-display: swap;
}

* {
    background-color: #37393A;
    
    box-sizing: border-box;
    font-size: 20px;

    margin: 0;
    padding: 0;

    font-family: "Vera Mono";
}

body {
    min-width: 320px;
}

#app {
    position: relative;
    height: 100vh;
    min-height: 568px;
}

h1 {
    font-size: 2.2rem;
}
h2 {
    font-size: 2rem;
}
h3 {
    font-size: 1.7rem;
}
h4 {
    font-size: 1.4rem;
}
h5 {
    font-size: 1.2rem;
}
header, main, footer {
    width: 100%;
}

header {
    color: #C7D3DD;
    border-bottom: 1px #141515 solid;

    padding: 0 0.5rem;
    height: var(--header-height);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.title-box {
    display: flex;
    align-items: center;
}

#title {
    display: block;
}

#options-button {
    cursor: pointer;
    border: none;
    outline: none;
    color: white;
    line-height: 0;
}

#options-window {
    position: absolute;
    right: 0;

    padding: 1em;
    border: 2px solid #E8EEF2;

    color: #E8EEF2;
}

#options-window input {
    width: 3em;
    text-align: center;
    margin: 0 1em;
    border: none;
    border-bottom: 1px #E8EEF2 solid;
    color: #E8EEF2;
}

.options-window_input-row {
    margin-bottom: 1rem;
    line-height: 1rem;
}

.options-window_input-row .react-toggle {
    margin-left: 1rem;
}

.options-window_input-row .react-toggle--checked:hover .react-toggle-track {
    background-color: #77b6ea;
}

.options-window_input-row .react-toggle--checked .react-toggle-track {
    background-color: #77b6ea;
}

/* react toggle button */
.react-toggle-track div {
    background-color: inherit;
}

#options-window_submit-row {
    margin-top: 3rem;
    display: flex;
    justify-content: center;
}

button {
    color: white;

    padding: 0.4rem;
    border: 2px solid #C7D3DD;
    border-radius: 2px;

    font-size: 1.5rem;
}

main {
    display: flex;
    justify-content: center;
}

.column {
    display: flex;
    flex-direction: column;
}

#game-window {
    margin: 0.3rem;
    padding: 0.3rem;

    display: flex;
    flex-direction: column;
    align-items: center;

    color: #E8EEF2;
}

#game-controls {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.display-rate {
    margin-bottom: 0.5rem;
}

.random-binary {
    font-size: 3rem;
}

.game-stopped-notice-box {
    min-height: 130px;
}

.game-stopped-notice {
    text-align: center;
    color: #4F5254;
    padding: 0.2rem;
    margin: 0.5rem 0;
    border: 2px solid #4F5254;
}

.game-stopped-notice_title {
    font-size: 2rem;
}

.input-container {
}

.user-guess-input {
    outline: none;
    border: none;
    border-bottom: 1px #E8EEF2 solid;
    text-align: center;
    font-size: 3rem;
    color: inherit;
}

footer {
    position: absolute;
    bottom: 0;

    height: var(--footer-height);
    display: flex;
    justify-content: center;
}

#maker {
    color: #D6C9C9;
    display: block;
}

.hidden {
    display: none;
}

@media screen and (min-width: 576px) {
    #game-window {
        margin: 1rem;
        padding: 1rem;
        border: 2px solid #E8EEF2;
    }
}
